import { NavLink } from "react-router-dom";
import Header from "./Header";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTachographDigital } from "@fortawesome/free-solid-svg-icons";

function Home() {


    return (
        <>
            <Header />
            <section className="dark:bg-stone-900 dark:text-gray-100 transition-all duration-100 ease-in h-screen">
                <div className='grid md:grid-cols-7 w-full pt-20 md:pl-4 md:pr-52 h-screen overflow-y-auto scroll-design'>
                    <div className="md:col-span-7 md:p-4 rounded-md md:rounded-l-md md:rounded-r-none">
                        <section>
                            <span className='inline-block place-content-center mr-4 text-blue-800 dark:text-blue-300 text-lg ml-2'><FontAwesomeIcon icon={faTachographDigital} /></span>
                            <h5 className="inline-flex items-center">داشبورد</h5>
                            <p className='px-8 text-sm md:text-base'>
                                سرویس مورد نظر خود را انتخاب نمایید.
                            </p>
                        </section>
                        <section>
                            <nav>
                                <ul className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 m-0 p-0">

                                    <NavLink to='/number-inquiry' className=' text-bk'><li className=" bg-gray-200 shadow-sm hover:shadow hover:bg-gray-300 md:h-48  py-4 px-3 m-2 rounded cursor-pointer transition-all duration-100 ease-in">
                                        <h6><strong>استعلام شماره</strong></h6>
                                        <p className="m-0">با وارد کردن شماره، نام یا شناسه کاربری به صورت تکی یا انبوه از موجودیت اکانت در شبکه‌های اجتماعی مختلف آگاه می‌شوید.</p>
                                    </li>
                                    </NavLink>
                                    {/* <NavLink to='/social-inquiry' className='text-bk'><li className=" bg-gray-200 shadow-sm hover:shadow hover:bg-gray-300 md:h-48  py-4 px-3 m-2 rounded cursor-pointer transition-all duration-100 ease-in">
                                    <h6><strong>استعلام اکانت</strong></h6>
                                    <p className="m-0"> با انتخاب نوع شبکه اجتماعی و وارد کردن نام کاربری و یا شناسه، از جزئیات اکانت مورد نظر آگاه می‌شوید.</p>
                                </li>
                                </NavLink> */}
                                    <NavLink to='/input-history' className=' text-bk'><li className=" bg-gray-200 shadow-sm hover:shadow hover:bg-gray-300 md:h-48  py-4 px-3 m-2 rounded cursor-pointer transition-all duration-100 ease-in">
                                        <h6><strong>تاریخچه استعلام شماره</strong></h6>
                                        <p className="m-0">در این بخش تاریخچه استعلام شماره‌هایی که به سیستم داده‌ شده‌اند، به همراه جزئیات و نتیجه استعلام قابل مشاهده است.</p>
                                    </li>
                                    </NavLink>
                                    <NavLink to='/input-history' className=' text-bk'><li className=" bg-gray-200 shadow-sm hover:shadow hover:bg-gray-300 md:h-48  py-4 px-3 m-2 rounded cursor-pointer transition-all duration-100 ease-in">
                                        <h6><strong>تاریخچه واتساپ</strong></h6>
                                        <p className="m-0">در این بخش تاریخچه اکانت‌هاواتساپی که جهت استعلام اطلاعات به سیستم داده‌ شده‌اند، به همراه جزئیات و نتیجه استعلام قابل مشاهده است.</p>
                                    </li>
                                    </NavLink>
                                </ul>
                            </nav>
                        </section>
                    </div>
                </div>
            </section>
        </>
    )

}

export default Home
